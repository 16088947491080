import { createStore, applyMiddleware ,combineReducers } from 'redux';
import { thunk } from 'redux-thunk'; // if using Redux Thunk middleware
// import userReducer from './userReducer';
import contentReducer from './reducer/post-reducer';
import metaReducer from './reducer/meta-reducer';

const rootReducer = combineReducers({
    content: contentReducer,
    meta: metaReducer
  });

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;