import { UPDATED_CONTENT, CLEAR_UPDATED_CONTENT } from './type/type';

const setUpdateContent = (content, category) =>
({
    type: UPDATED_CONTENT,
    content: content,
    category: category,
});

const clearUpdateContent = () => {
    return {
        type: CLEAR_UPDATED_CONTENT
    }
}

export {
    setUpdateContent,
    clearUpdateContent,
}