import React from "react";


export default function Footer() {

    return (
        <footer className="py-4 bg-gray-900">
            <div className="text-center text-black text-xs">Copyright © {process.env.REACT_APP_DOMAIN_TITLE} 2024. All rights reserved.
                {/* <a className="underline hover:no-underline" target="_blank" title="PerfectScore.com">PerfectScore.com</a> */}
                <button
                    style={{
                        background: 'none',
                        border: 'none',
                        color: 'white',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    }}
                >
                </button>
            </div>
        </footer>
    )
}