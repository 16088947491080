import { SET_HEADER_META } from './type/type';

const setHeaderMeta = (content) =>
({
    type: SET_HEADER_META,
    content: content,
});

export {
    setHeaderMeta,
}