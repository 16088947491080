
import React, { useEffect, useState } from 'react'
import Header from '../component/header/header'
import ScrollToTop from '../component/scroll-to-top/scroll-to-top'
import HeroSectionWithoutNumber from '../component/hero-section/hero-section-without-number';
import SideWidgetNews from '../component/side-widget/news';
import SideWidgetFeatured from '../component/side-widget/featured';
import SideWidgetHeadline from '../component/side-widget/headline';
import Banner from '../component/banner/banner';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import apiClient from '../instance/api-client';
import { setHeaderMeta } from '../component/redux/action/meta-action'
import SEO from '../component/seo/seo';

export default function AllPages() {
    const dispatch = useDispatch()
    const redirect = useNavigate()
    // const [pathName, setPathName] = useState('')
    const [page, setPage] = useState(null)
    const [meta, setMeta] = useState(null)
    const [subCategory, setSubCategory] = useState(null)
    const [categories, setCategories] = useState({
        name: null,
        urlName: null,
    })
    const [otherCategories, setOtherCategories] = useState({
        name: null,
        urlName: null,
    })

    const pageMeta = useSelector(state => state.meta.meta)

    useEffect(() => {
        if (!pageMeta.content) {
            const loadTDK = async (domain) => {
                if (!pageMeta.meta) {
                    const response = await apiClient.get('server-hosting?domain=' + domain)
                    if (response.data && response.data.data) {
                        dispatch(setHeaderMeta(response.data.data))
                    }
                    pageCallBack(page)
                }
            }
            let origin = window.location.origin
            origin = origin.replace('http://', '').replace('https://', '')
            const exist = /www\../.test(origin)
            if (!exist) {
                origin = 'www.' + origin
            }
            loadTDK(origin)
        } else {
            pageCallBack(page)
        }
    }, [pageMeta])

    const pageCallBack = (pages) => {
        if (pages) {

            setPage(pages)
            const pathnameParts = decodeURIComponent(window.location.pathname).split('/').filter(part => part !== '');
            const category = pages.filter((category) => (category.categories.urlName === pathnameParts[0] || category.categories.name === pathnameParts[0]))
            const categoryTest = pages.filter((category) => (category.categories.urlName !== pathnameParts[0] && category.categories.name !== pathnameParts[0]))

            setCategories({
                name: category[0].categories.name,
                urlName: category[0].categories.urlName
            })

            setOtherCategories({
                name: categoryTest[0].categories.name,
                urlName: categoryTest[0].categories.urlName
            })

            if (pageMeta.content && pageMeta.content[0]) {
                const tempMeta = pageMeta.content[0]
                switch (category[0].categories.urlName) {
                    case 'football-situation-articles':
                        setMeta({ title: tempMeta.footTitle, desc: tempMeta.footDesc, keyword: tempMeta.footKey })
                        break;
                    case 'League-news':
                        setMeta({ title: tempMeta.leagueTitle, desc: tempMeta.leagueDesc, keyword: tempMeta.leagueKey })
                        break;
                    case 'international-events':
                        setMeta({ title: tempMeta.intTitle, desc: tempMeta.intDesc, keyword: tempMeta.intKey })
                        break;
                    default:
                        break;

                }
            }
            // setMeta(category[0])
            const data = category[0].subPage.filter((subPage) => (subPage.categories.name !== "全部"))

            setSubCategory(data)
        }
    }

    useEffect(() => {
        setSubCategory(null)
        pageCallBack(page)

    }, [window.location.href])


    return (
        <>
            <Banner position={'mainBtm'} />
            <Header pageCallBack={pageCallBack} />
            <ScrollToTop />
            <SEO
                type={'article'}
                title={meta && meta.title}
                description={meta && meta.desc}
                keyword={meta && meta.keyword}
                name={process.env.REACT_APP_DOMAIN_NAME}
            />

            <div className="max-w-screen-xl mx-auto">
                <div className="scp-breadcrumb">
                    <ul className="breadcrumb">
                        <li><a onClick={() => redirect('/')}>首页</a></li>
                        <li><a >{categories.name}</a></li>
                        <li className="active">全部</li>
                    </ul>
                </div>

                <div className="max-w-screen-xl mx-auto px-4 py-24">
                    <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">

                        <div className="w-full lg:w-3/4 mb-6 lg:mb-0">

                            <Banner position={'allTop'} />

                            {subCategory && subCategory.flatMap((subPage, index) => (
                                <React.Fragment key={index}>
                                    <HeroSectionWithoutNumber title={subPage.categories.name} category={subPage.categories.urlName ? subPage.categories.urlName : subPage.categories.name} logo={subPage.categories.logo} />
                                </React.Fragment>

                            ))}
                            <Banner position={'allBtm'} />

                        </div>

                        <aside className="w-full lg:w-1/4">
                            <div className="md:mx-3 sticky top-6" id="__FPSC_ID_1_1641419081408">

                                <SideWidgetNews title="相关新闻" category="足球变动" />

                                <SideWidgetFeatured title="精选" category="热门资讯" />

                                <SideWidgetHeadline title="头条新闻" category={otherCategories.urlName ? otherCategories.urlName : otherCategories.name} />

                                <Banner position={'allSid'} />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </>

    )
}