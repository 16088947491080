/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Header from '../component/header/header'
import Footer from '../component/footer/footer'
import ScrollToTop from '../component/scroll-to-top/scroll-to-top';
import {  useNavigate } from "react-router-dom";
import apiClient from '../instance/api-client';
import io from 'socket.io-client';
// import HeroSection from '../component/hero-section/hero-section';
import Banner from '../component/banner/banner';
// import HeroSectionWithAllImage from '../component/hero-section/hero-section-with-all-image';
// import ListView from '../component/list-view/list-view';
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateContent } from '../component/redux/action/post-action';
import { setHeaderMeta } from '../component/redux/action/meta-action'
import HeroSectionWithoutNumber from '../component/hero-section/hero-section-without-number';
import SEO from '../component/seo/seo';


export default function MainPage() {
    const redirect = useNavigate()
    const [mainContent, setMainContent] = useState([])
    const dispatch = useDispatch()
    const [meta , setMeta] = useState({
        title:'',
        desc : '',
        keyword:'',
    })

    const pageMeta = useSelector(state => state.meta.meta)

    useEffect(() => {
        if (pageMeta.content) {
            const temp = pageMeta.content[0]
          setMeta({title:temp.mainTitle,desc:temp.mainDesc,keyword:temp.mainKey})
        }
    }, [pageMeta])


    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_WS_URL);

        socket.on('post', (data) => {
            dispatch(setUpdateContent(data.content, data.category))
            loadContentPage()
        });
        let origin = window.location.origin
        origin = origin.replace('http://', '').replace('https://', '')
        const exist = /www\../.test(origin)
        if (!exist) {
            origin = 'www.' + origin
        }
        
        loadTDK(origin)
    }, [])

    const loadTDK = async (domain) => {
        if (!pageMeta.meta) {
            const response = await apiClient.get('server-hosting?domain=' + domain)

            if (response.data && response.data.data) {
                dispatch(setHeaderMeta(response.data.data))
            }
        }
        loadContentPage()
    }

    const loadContentPage = async () => {
        try {
            const response = await apiClient.get('contents', { params: { take: 4 } })
            if (response.data && response.data.data)
                setMainContent(response.data.data)
        } catch (error) {
            console.info(error)
        }
    }

    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);

    //     // Splitting the date based on the actual separator returned by toLocaleDateString
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');

    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }
    
    return (
        <>
            <Banner position={'mainBtm'} />

            <Header pageCallBack={() => { }} />
            {/* for main page display  */}
            <SEO
                type={'article'}
                title={meta.title}
                description={meta.desc}
                keyword={meta.keyword}
                name={process.env.REACT_APP_DOMAIN_NAME}
            />

            <ScrollToTop />

            <div className="max-w-screen-xl mx-auto px-4 py-24">
                <Banner position={'mainTop'} />
                <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                    <main className="w-full lg:w-full mb-8 lg:mb-0">
                        <div className="lna-news-day-home__section">
                            <div className="lna-news-group">
                                {mainContent && (mainContent[0] ? (<div className="lna-news-group__column">
                                    <div className="lna-news-block">
                                        <a className="lna-news-block__link-container" target="_blank" onClick={() => redirect(`/${mainContent[0].categories[0].urlName ? mainContent[0].categories[0].urlName : mainContent[0].categories[0].name}/${process.env.REACT_APP_SHORT_NAME}${mainContent[0].permLink}`)}>
                                            <img src={`${process.env.REACT_APP_API_URL}uploads/${mainContent[0] && mainContent[0].image.filename}`} className="lna-news-block__image" alt={mainContent[0].image.alt} />
                                            <div className="lna-news-block__content-wrapper">
                                                <div className="lna-news-block__title">
                                                    {mainContent[0].categories && mainContent[0].categories.slice(0, 4).flatMap((category, index) => {

                                                        return (<span className={index === 0 ? "lna-news-block__category" : "lna-news-block__category-epl"} data-text-value={category.name} key={index}>
                                                            {category.name}
                                                        </span>
                                                        )
                                                    })}

                                                    <div className="lna-news-block__title-text">{mainContent[0].title}</div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>) :
                                    (
                                        <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/coming-soon.jpg" alt='' />
                                    ))}
                                <div className="lna-news-group__column">
                                    {mainContent && mainContent[1] && (<div className="lna-news-group__first-row">
                                        <div className="lna-news-block">
                                            <a className="lna-news-block__link-container" target="_blank" onClick={() => redirect(`/${mainContent[1].categories[0].urlName ? mainContent[1].categories[0].urlName : mainContent[1].categories[0].name}/${process.env.REACT_APP_SHORT_NAME}${mainContent[1].permLink}`)}>
                                                <img src={`${process.env.REACT_APP_API_URL}uploads/${mainContent[1] && mainContent[1].image.filename}`} className="lna-news-block__image" alt={mainContent[1].image.alt} />
                                                <div className="lna-news-block__content-wrapper">
                                                    <div className="lna-news-block__title">
                                                        {mainContent[1].categories && mainContent[1].categories.slice(0, 4).flatMap((category, index) => {

                                                            return (<span className={index === 0 ? "lna-news-block__category" : "lna-news-block__category-epl"} data-text-value={category.name} key={index}>
                                                                {category.name}
                                                            </span>
                                                            )
                                                        })}
                                                        <div className="lna-news-block__title-text">{mainContent[1].title}</div>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>)}
                                    <div className="lna-news-group__second-row">
                                        {mainContent && mainContent[2] && (<div className="lna-news-block">
                                            <a className="lna-news-block__link-container" target="_blank" onClick={() => redirect(`/${mainContent[2].categories[0].urlName ? mainContent[2].categories[0].urlName : mainContent[2].categories[0].name}/${process.env.REACT_APP_SHORT_NAME}${mainContent[2].permLink}`)}>
                                                <img src={`${process.env.REACT_APP_API_URL}uploads/${mainContent[2] && mainContent[2].image.filename}`} className="lna-news-block__image" alt={mainContent[2].image.alt} />
                                                <div className="lna-news-block__content-wrapper">
                                                    <div className="lna-news-block__title">
                                                        {mainContent[2].categories && mainContent[2].categories.slice(0, 4).flatMap((category, index) => {

                                                            return (<span className={index === 0 ? "lna-news-block__category" : "lna-news-block__category-epl"} data-text-value={category.name} key={index}>
                                                                {category.name}
                                                            </span>
                                                            )
                                                        })}
                                                        <div className="lna-news-block__title-text">{mainContent[2].title}</div>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>)}
                                        {mainContent && mainContent[3] && (<div className="lna-news-block">
                                            <a className="lna-news-block__link-container" target="_blank" onClick={() => redirect(`/${mainContent[3].categories[0].urlName ? mainContent[3].categories[0].urlName : mainContent[3].categories[0].name}/${process.env.REACT_APP_SHORT_NAME}${mainContent[3].permLink}`)}>
                                                <img src={`${process.env.REACT_APP_API_URL}uploads/${mainContent[3] && mainContent[3].image.filename}`} className="lna-news-block__image" alt={mainContent[3].image.alt} />
                                                <div className="lna-news-block__content-wrapper">
                                                    <div className="lna-news-block__title">
                                                        {mainContent[3].categories && mainContent[3].categories.slice(0, 4).flatMap((category, index) => {

                                                            return (<span className={index === 0 ? "lna-news-block__category" : "lna-news-block__category-epl"} data-text-value={category.name} key={index}>
                                                                {category.name}
                                                            </span>
                                                            )
                                                        })}
                                                        <div className="lna-news-block__title-text">{mainContent[3].title}</div>

                                                    </div>
                                                </div>
                                            </a>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>



                        <HeroSectionWithoutNumber title="热门资讯" category="hot-news" logo={false} />
                        <Banner position={'mainMid'} />
                        <HeroSectionWithoutNumber title="联赛最新动态" category="League-news" logo={false} />

                        <HeroSectionWithoutNumber title="国际赛事" category="international-events" logo={false} />

                        {/* <HeroSection title="热门资讯" category="hot news" />

                        <HeroSectionWithAllImage title="联赛最新动态" category="League news" />

                        <ListView title="国际赛事" category="international events" /> */}

                    </main>
                </div >
            </div >
            <Footer />

        </>
    )
}