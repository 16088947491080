import React from 'react';
import { Helmet } from 'react-helmet';



export default function SEO({ title, description, name, type, keyword }) {

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="title" content={title} data-react-helmet="true"></meta>
            <meta name="description" content={description} />
            <meta name='keyword' content={keyword} />

            <meta property="og:title" content={title} />
            <meta property="og:type" content={type} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={name} />
            <meta property="og:site_name" content={title} />

            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />

        </Helmet>
    )
}