/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Header from '../component/header/header'
import Footer from '../component/footer/footer'
import ScrollToTop from '../component/scroll-to-top/scroll-to-top'
import apiClient from '../instance/api-client';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Banner from '../component/banner/banner';
import SideWidgetNews from '../component/side-widget/news';
import SideWidgetFeatured from '../component/side-widget/featured';
import HeroSectionNews from '../component/hero-section/hero-section-news';

export default function ContentPage() {
    const redirect = useNavigate()
    const [content, setContent] = useState(null)
    // const [page, setPage] = useState(null)
    const [categories, setCategories] = useState({
        name: null,
        urlName: null,
    })

    useEffect(() => {
        const pathnameParts = decodeURIComponent(window.location.pathname).split('/').filter(part => part !== '');
        let origin = window.location.origin

        const loadContentPermLink = async (permLink) => {

            try {
                const response = await apiClient.get('contents/perm/' + permLink)
                if (response.status === 200 && response.data.statusCode === 200 && response.data.data) {
                    const testing = response.data.data
                    const afterChange = testing.content.replaceAll("<br>", `<br><span style='display:none;'>${origin}</span>`)

                    setContent({ ...response.data.data, content: afterChange })
                } else {
                    redirect('/404')
                }

            } catch (error) {
                console.info(error)
            }
        }

        loadContentPermLink(pathnameParts[1].replace(process.env.REACT_APP_SHORT_NAME, ''))
    }, [window.location.href])

    const pageCallBack = (pages) => {
        if (pages) {
            // setPage(pages)
            const pathnameParts = decodeURIComponent(window.location.pathname).split('/').filter(part => part !== '');
            const category = pages.filter((category) =>
                category.subPage.some((subPage) =>
                    subPage.categories.urlName === pathnameParts[0] || subPage.categories.name === pathnameParts[0]
                ))

            if (category[0]) {
                const subCategory = category[0].subPage.filter((subPage) => subPage.categories.urlName === pathnameParts[0] || subPage.categories.name === pathnameParts[0])
                setCategories({
                    name: category[0].categories.name,
                    urlName: category[0].categories.urlName,
                    subName: subCategory[0].categories.name,
                    subUrlName: subCategory[0].categories.urlName,
                })
            } else {
                loadCategoryDetail(pathnameParts[0])
            }
            // const data = category[0].subPage.filter((subPage) => (subPage.categories.name !== "全部"))
            // setSubCategory(data)
        }
    }

    const loadCategoryDetail = async (category) => {
        try {
            const response = await apiClient.get('categories/searchOnly/' + category)
            if (response.status === 200) {
                setCategories({
                    name: response.data.data[0].categories_name,
                    urlName: response.data.data[0].categories_url_name,
                })
            }

        } catch (error) {
            console.info(error)
        }
    }


    const formatDate = (dateString) => {
        const options = { month: "numeric", day: "numeric", year: "numeric" };
        const locale = 'zh-CN';
        const formattedDate = new Date(dateString).toLocaleDateString(locale, options);

        // Splitting the date based on the actual separator returned by toLocaleDateString
        const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');

        return `${parts[0]}-${parts[1]}-${parts[2]}`;
    }

    return (
        <>
            <Banner position={'mainBtm'} />
            <Header pageCallBack={pageCallBack} />
            <ScrollToTop />
            {content &&
                <>
                    <Helmet>
                        <meta property="og:title" content={content && content.title} />
                        <title>{content && content.title}</title>
                        <meta name="title" content={content && content.title} data-react-helmet="true"></meta>
                        <meta property=''></meta>
                        <meta property="og:title" content={content && content.title} />
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content="" />
                        <meta property="og:image" content="images/24x14.png" />
                        <meta name="author" content="" />
                        <meta name="description" content={content && content.metaDescription} />
                        <meta name='keyword' content={content && content.metaKeyword} />
                    </Helmet>

                    <div className="max-w-screen-xl mx-auto px-4 py-24">
                        <div>
                            <ul className="breadcrumb bread-margin-top">
                                <li><a onClick={() => redirect('/')}>首页</a></li>
                                {(categories.urlName || categories.name) && (<li><a onClick={() => redirect(`/${categories.urlName ? categories.urlName : categories.name}`)}>{categories.name}</a></li>)}
                                {(categories.subUrlName || categories.subName) && (<li ><a onClick={() => redirect(`/${categories.subUrlName ? categories.subUrlName : categories.subName}`)}>{categories.subName}</a></li>)}
                                <li className="active">{content.title}</li>
                            </ul>
                        </div>
                        <div className="flex flex-col flex-wrap md:flex-row md:-mx-3">
                            <main className="w-full lg:w-3/4 mb-8 lg:mb-0">
                                <Banner position={"contentTop"} />
                                <article className="md:mx-3 bg-gray-900 shadow-lg">
                                    <Banner position={"qrCode"} content={content} />

                                    <div className="p-8 bg-white">
                                        <h1 className="font-bold text-4xl md:text-5xl pb-8 leading-tight">{content && content.title}</h1>
                                        {/* <div className="bg-gray-300 mb-6 inline-block py-1 px-2 italic text-sm">发表于: <time dateTime="2021-11-19 20:00">{content && formatDate(content.createdAt)}</time> 由 <a className="underline hover:no-underline">{content && content.user.name}</a></div> */}
                                        <div className="mb-6 inline-block py-1 px-2 italic text-sm">作者 : {content && content.user.name}   <time dateTime="2021-11-19 20:00">{content && formatDate(content.createdAt)}</time> </div>
                                        <div className="leading-normal">
                                            <div className="custom-content" dangerouslySetInnerHTML={{ __html: content.content }} />

                                            <br />

                                        </div>
                                    </div>
                                </article>

                                <Banner position={"contentBtm"} />

                                <HeroSectionNews category={categories.subUrlName ? categories.subUrlName : categories.subName ? categories.subName : categories.urlName ? categories.urlName : categories.name} />

                            </main>
                            <aside className="w-full lg:w-1/4">
                                <div className="md:mx-3 sticky top-6" id="__FPSC_ID_1_1641419081408">

                                    <SideWidgetNews title="相关新闻" category="足球变动" />

                                    <SideWidgetFeatured title="精选" category="热门资讯" />

                                    <Banner position={'contentSid'} />
                                </div>
                            </aside>
                        </div>
                    </div>
                </>}
            {/* for Content display  */}
            <Footer />
        </>
    )
}