import React, { useState, useEffect } from "react";
import apiClient from "../../instance/api-client"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HeroSectionWithoutNumber(props) {
    const redirect = useNavigate()
    const { title, category, logo = null } = props
    const [contenteList, setContentList] = useState(null)
    const [contentsTopRow, setContentsTopRow] = useState(null)
    const [contentsSecondRow, setContentsSecondRow] = useState(null)

    const [categories, setCategories] = useState({
        name: null,
        urlName: null,
        logo: null
    })

    const updatedPost = useSelector(state => state.content.post)

    useEffect(() => {
        if (updatedPost.category) {
            const result = updatedPost.category.filter((category) => (category.name === title || category.urlName === props.category))
            if (result.length > 0) {
                const oldContent = [
                    ...(contentsTopRow || []),
                    ...(contentsSecondRow || []),
                    ...(contenteList || []),
                ]

                const newContentExist = oldContent.filter((content) => content.id === updatedPost.content.id)
                if (newContentExist.length === 0) {
                    const newContent = [
                        updatedPost.content,
                        ...oldContent
                    ]
                    if (newContent)
                        setContent(newContent.slice(0, 8))
                }
            }
        }
    }, [updatedPost])

    useEffect(() => {
        setCategories({})
        setContentList([])
        setContentsTopRow([])
        setContentsSecondRow([])
        const loadCategoryPost = async () => {
            try {
                const response = await apiClient.get('contents/category/' + category, { params: { take: 20} })
                // const response = await apiClient.get('categories/search/' + category)
                if (response.status === 200 && response.data.data) {

                    if (response.data.data[0])
                        setCategories({
                            name: response.data.data[0].categories[0].name,
                            urlName: response.data.data[0].categories[0].urlName
                        })
                    const data = response.data.data
                    setContent(data)

                    // setCategories({
                    //     name: response.data.data[0].name,
                    //     urlName: response.data.data[0].urlName,
                    // })

                    // const data = response.data.data[0].contents.filter((content) => (content.id != null));
                    // if (Object.keys(data).length > 0) {
                    //     setContent(data)
                    // }

                }
            } catch (error) {
                console.info(error)
            }
        }

        loadCategoryPost()
    }, [title, category])

    const setContent = (content) => {
        setContentList(content.slice(4, 16))
        setContentsTopRow(content.slice(0, 2))
        setContentsSecondRow(content.slice(2, 4))
    }

    // const formatDate = (dateString) => {
    //     const options = { month: "numeric", day: "numeric", year: "numeric" };
    //     const locale = 'zh-CN';
    //     const formattedDate = new Date(dateString).toLocaleDateString(locale, options);

    //     // Splitting the date based on the actual separator returned by toLocaleDateString
    //     const parts = formattedDate.split(/[\u200E/]/).filter(part => part.trim() !== '');

    //     return `${parts[1]} 月 ${parts[2]} 日`;
    // }

    const blockWithImage = (content, key) => {
        return (

            <div className="lna-news-block" key={key}>
                <a className="lna-news-block__link-container" onClick={() => redirect(`/${categories.urlName ? categories.urlName : categories.name}/${process.env.REACT_APP_SHORT_NAME}${content.permLink}`)}>
                    <img src={`${process.env.REACT_APP_API_URL}uploads/${content.image.filename}`} className="lna-news-block__image" alt="" />
                    <div className="lna-news-block__content-wrapper">
                        <div className="lna-news-block__title">
                            {content.categories && content.categories.slice(0, 4).flatMap((categorie, index) => {
                                if (categorie.name !== title && categorie.urlName !== category)
                                    return (<span className="lna-news-block__category" data-text-value={categorie.name} key={categorie.name + index}>
                                        {categorie.name}
                                    </span>)
                                return null
                            })}
                            <div className="lna-news-block__title-text">{content.title}</div>

                            {/* <div className="d-flex align-items-center">
                                <div className="d-flex flex-grow-1 flex-column justify-content-center align-items-start">
                                    <div className="small fw-bold">{content.user.name}</div>
                                    <div className="small">{formatDate(content.createdAt)}</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </a>
            </div>

        )
    }

    const blockWithOutImage = (content, key) => {

        return (
            <a className="flex items-center group mb-3 border-b" onClick={() => redirect(`/${categories.urlName ? categories.urlName : categories.name}/${process.env.REACT_APP_SHORT_NAME}${content.permLink}`)} key={key}>
                <div className="flex-1">
                    <dt><h2 className="text-lg leading-tight hover:text-red-600 text-truncate">{content.title}</h2></dt>
                </div>
            </a>
        )
    }

    const transparentContent = (contents) => {

        if (contents.length === 1) {
            return <div className="lna-news-block" style={{ visibility: 'hidden' }}>
                <a className="lna-news-block__link-container" >
                    <img src={`${process.env.REACT_APP_API_URL}uploads/${contents[0].image.filename}`} className="lna-news-block__image" alt="" />
                </a>
            </div>

        }
    }

    return (<>
        <div className="lna-news-titled-line" >
            <div className="lna-news-titled-line__stick"></div>
            <div className="lna-news-titled-line__title">{logo && <img alt=" " className="mr-xs ico" src={logo ? process.env.REACT_APP_API_URL + 'uploads/' + logo : `"images/icon-speaker.png"`} />}
                {categories.logo}
                {title}</div>
            <div className="lna-news-titled-line__line"></div>
        </div>
        <div className="widget__header">
            <span className="widget__header-title border-b"></span>
            <a onClick={() => redirect(`/${category}`)} className="widget__header-link border-b btn-more">更多</a>
        </div>
        {((contentsTopRow && contentsTopRow.length > 0) || (contentsSecondRow && contentsSecondRow.length > 0)) ?
            <div className="flex flex-col md:flex-row -mx-1.5 p-4">
                <div className="lna-news-day-home__section w-full ">
                    <div className="lna-news-group auto-height">
                        <div className="lna-news-group__column3">
                            <div className="lna-news-group__second-row second-row-first-child">
                                {contentsTopRow && (
                                    <>
                                        {contentsTopRow.flatMap((content, index) => {
                                            return (
                                                blockWithImage(content, index)
                                            )
                                        })}
                                        {transparentContent(contentsTopRow)}
                                    </>
                                )}
                            </div>
                            <div className="lna-news-group__second-row">
                                {contentsSecondRow && (
                                    contentsSecondRow.flatMap((content, index) => {
                                        return (
                                            blockWithImage(content, index)
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:w-4/12 mx-1.5 text-truncate">
                    <dl className="faq" />
                    {contenteList && (
                        contenteList.flatMap((content, index) => {
                            return (
                                blockWithOutImage(content, index)
                            )
                        })
                    )}
                </div>
            </div> :
            (
                <img className="mx-auto block w-screen-md my-0 sm:mt-0 p-0 sm:p-5" src="/images/coming-soon.jpg" alt='' />
            )}

    </>)
}