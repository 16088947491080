import { SET_HEADER_META } from '../action/type/type';

const initialState = {
    meta: {
        content: null
    }
};

const metaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HEADER_META:
            return {
                meta: {
                    content: action.content, // Update content
                }
            };
        default:
            return state;
    }
};

export default metaReducer;